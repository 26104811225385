import {
  EyeOutlined,
  SearchOutlined,
  EditOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Tag,
  Card,
  Col,
  Row,
  Statistic,
  Divider,
  Popconfirm,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getOngoingPricingModel,
  getPrice,
  deactivePricingModel,
  activePricingModel,
} from "../../../actions/price";
import history from "../../../history";
const Pricing = ({
  getOngoingPricingModel,
  getPrice,
  deactivePricingModel,
  activePricingModel,
}) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getOngoingPricingModel();

    setData(res);
    const res1 = await getPrice();
    setData1(res1);
   
  }, []);
  let handleActiveModel = async (id) => {
    const res = await activePricingModel(id);
    if (res === 201) {
      const res = await getPrice("view");
      const res1 = await getOngoingPricingModel();
      setData(res1);
      setData1(res);
    }
  };
  const handleDeactiveModel = async () => {
    const res = await deactivePricingModel();
    if (res === 201) {
      const res = await getPrice("view");
      const res1 = await getOngoingPricingModel();
      setData(res1);
      setData1(res);
    }
  };
  const handleVisible=async(id)=>{
    history.push(`/pricing-details?pricing_id=${id}`);
  }

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Pricing Id",
      dataIndex: "pricing_id",
      key: "pricing_id",
      width: "15%",
      ...searchColumns("pricing_id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Create Time",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      ...searchColumns("created_at"),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      width: "15%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const createData = (
    id,
    pricing_id,
    name,
    created_at,
    active,

    action
  ) => {
    return {
      id,
      pricing_id,
      name,
      created_at,
      active,
      action,
    };
  };

  const rows = [
    data1
      ? data1?.models?.map((price, id) =>
          createData(
            id + 1,
            price?.pricing_id,
            price?.name,
            <>{moment.utc(price?.created_at).format("YYYY-MM-DD hh:mm A")}</>,
            <>
              {price?.active ? (
                <Button
                  size="small"
                  type="primary"
                  icon={<CheckCircleOutlined />}
                >
                  Actived
                </Button>
              ) : (
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleActiveModel(price?.pricing_id)}
                >
                  <Button size="small" type="danger" icon={<StopOutlined />}>
                    Make Active
                  </Button>
                </Popconfirm>
              )}
            </>,
            <>
              <Button size="small" type="primary" icon={<EyeOutlined />
            
            } onClick={() => {handleVisible(price.pricing_id)}}>
                View
              </Button>
              
            </>
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Pricing List"
      subTitle="Pricing Management"
    ></PageHeader>
  );
  const pricemodel = (
    <>
      <div
        style={{ padding: "10px", backgroundColor: "#3ab569", color: "white" }}
      >
        Model name: {data?.ongoing?.name}(Running)
        <Tag
          style={{
            display: "inlineBlock",
            position: "absolute",
            right: "0",
            marginRight: "50px",
          }}
        >
          VAT :7.5
        </Tag>
      </div>
      <br></br>
      <>
        <Divider style={{ backgroundColor: "#3ab569", color: "white" }}>
          Pricing In City
        </Divider>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row gutter={16}>
            &nbsp;
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Delivery Charge(TK)"
                  value={data?.ongoing?.in_city?.delivery_charge}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Return Charge(TK)"
                  value={data?.ongoing?.in_city?.return_charge}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Base Weight (kg)"
                  value={data?.ongoing?.in_city?.base_weight}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="Kg"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Max Weight (kg)"
                  value={data?.ongoing?.in_city?.max_weight}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="Kg"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Cash On Delivery (TK)"
                  value={data?.ongoing?.in_city?.cod}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Extra Charge Per KG (TK)"
                  value={data?.ongoing?.in_city?.ECPK}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
          </Row>
          <Row gutter={10}>
            &nbsp;
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="ODD Delivery Charge (TK)"
                  value={data?.ongoing?.in_city?.ODD_delivery_charge}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="ODD Return Charge(TK)"
                  value={data?.ongoing?.in_city?.ODD_return_charge}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="ODD Base Weight (kg)"
                  value={data?.ongoing?.in_city?.ODD_base_weight}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="Kg"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="ODD Max Weight (kg)"
                  value={data?.ongoing?.in_city?.ODD_max_weight}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="Kg"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="ODD Cash On Delivery (TK)"
                  value={data?.ongoing?.in_city?.ODD_cod}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="ODD Extra Charge Per KG (TK)"
                  value={data?.ongoing?.in_city?.ODD_ECPK}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
          </Row>
        </div>
      </>

      <>
        <Divider style={{ backgroundColor: "#3ab569", color: "white" }}>
          Pricing In Sub City
        </Divider>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row gutter={16}>
            &nbsp;
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Delivery Charge(TK)"
                  value={data?.ongoing?.in_sub_city?.delivery_charge}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Return Charge(TK)"
                  value={data?.ongoing?.in_sub_city?.return_charge}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Base Weight (kg)"
                  value={data?.ongoing?.in_sub_city?.base_weight}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="Kg"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Max Weight (kg)"
                  value={data?.ongoing?.in_sub_city?.max_weight}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="Kg"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Cash On Delivery (TK)"
                  value={data?.ongoing?.in_sub_city?.cod}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Extra Charge Per KG (TK)"
                  value={data?.ongoing?.in_sub_city?.ECPK}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="TK"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
          </Row>
        </div>
      </>

      <>
        <Divider style={{ backgroundColor: "#3ab569", color: "white" }}>
          ODD Timing
        </Divider>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row gutter={16}>
            &nbsp;
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Status"
                  value={data?.ongoing?.outer_city?.odd ? "Active" : "Inactive"}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix=""
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="Start Timing"
                  value={data?.ongoing?.odd_time?.start}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="am"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
            <Col span={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
              <Card>
                <Statistic
                  title="End Timing"
                  value={data?.ongoing?.odd_time?.end}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="pm"
                />
              </Card>
              &nbsp;
            </Col>
            <br />
          </Row>
        </div>
      </>
    </>
  );
  const deactiveallmodel = (
    <>
      <div style={{ padding: "10px", backgroundColor: "#3ab569", color: "white" }}>
        All Pricing Model
        <>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDeactiveModel()}
          >
            <Button
              size="small"
              type="danger"
              icon={<StopOutlined />}
              style={{
                display: "inlineBlock",
                position: "absolute",
                right: "0",
                marginRight: "50px",
                backgroundColor:'red',
              }}
            >
              Deactival All Model
            </Button>
          </Popconfirm>
        </>
      </div>
    </>
  );
  return (
    <Content>
      {Pageheader}
      <br></br>
      {pricemodel}
      {deactiveallmodel}

      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, {
  getOngoingPricingModel,
  getPrice,
  deactivePricingModel,
  activePricingModel,
})(Pricing);
