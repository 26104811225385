import axios from 'axios';

export default (token = false) => {
     
    
    return axios.create({
        // baseURL: 'https://devtest.bahonexpress.com/archon/v1/',
        // baseURL: 'http://api-hidayah.bahonexpress.com/archon/v1/',
        baseURL: 'https://api.bahonexpress.com/archon/v1/',
        // baseURL: 'https://api.jatayat3pl.com/archon/v1/',
        headers: {
            // 'authorization': `Bearer 1212121212`,
             'content-type': 'application/json',
             
            'authorization': `Bearer ${token}`
        }
    })
}