import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBahonDetails } from "../../../actions/account";
import {
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
} from "antd";
import { Content } from "antd/lib/layout/layout";

const BahonDetails = ({ getBahonDetails }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getBahonDetails();
    setData(res);
   
  }, []);

  
  const bahon_details = (
    <Row gutter={16} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Col
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        style={{ textAlign: "center" }}
      >
        <Divider>Bank Account Details</Divider>
        <Card
          title={data?.bank_account?.bank_name}
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
          }}
          bordered={false}
        >
          <Row style={{textAlign: 'left'}}>
            <Col xs={12}>AC</Col><Col xs={12}>{data?.bank_account?.account_number}</Col>
            <Col xs={12}>Branch Name</Col><Col xs={12}>{data?.bank_account?.branch_name}</Col>
            <Col xs={12}>Route No</Col><Col xs={12}>{data?.bank_account?.routing_number}</Col>
            <Col xs={12}>Account Balance</Col><Col xs={12} style={{ fontSize: '16px', fontWeight: '600' }}>{data?.bank_account?.account_balance?.toFixed(2)}Tk</Col>
          </Row>
        </Card>
      </Col>
      <Col
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        style={{ textAlign: "center" }}
      >
        <Divider>Mobile Banking Account Details</Divider>
        <Card
          title={"Mobile Banking"}
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
            display: 'inlineBlock'
          }}
          bordered={false}
        >
          <Row scroll={true}>
            <Col xs={8}>Service Name</Col><Col xs={8}>Account No</Col><Col xs={8}>Balance</Col>
            <br />
            <br />
            <Col xs={8}>bKash</Col><Col xs={8}>{data?.mobile_bank?.bkash?.mobile_banking_number}</Col><Col xs={8}  style={{ fontSize: '16px', fontWeight: '600' }}>{data?.mobile_bank?.bkash?.account_balance?.toFixed(2)} tk</Col>
            <Col xs={8}>Rocket</Col><Col xs={8}>{data?.mobile_bank?.rocket?.mobile_banking_number}</Col><Col xs={8}  style={{ fontSize: '16px', fontWeight: '600' }}>{data?.mobile_bank?.rocket?.account_balance?.toFixed(2)} tk</Col>
            <Col xs={8}>Nagad</Col><Col xs={8}>{data?.mobile_bank?.nagad?.mobile_banking_number}</Col><Col xs={8}  style={{ fontSize: '16px', fontWeight: '600' }}>{data?.mobile_bank?.nagad?.account_balance?.toFixed(2)} tk</Col>
          </Row>
          
         
        </Card>
      </Col>
      <Col
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        
      ><Divider>Cash Details</Divider>
        <Card
          title={'Balance in Cash'}
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
            fontSize: '16px', 
            fontWeight: '600',
          }}
          bordered={false}
        >
           {data?.cash?.account_balance?.toFixed(2)} TK <br></br>
        </Card>
      </Col>
      
    </Row>
  );
  
  const DemoBox = props => <div style={{
    backgroundColor:'#3ab569',textAlign:'center',padding: '15px', 
    color:'white', boxShadow:'5px 9px 10px -5px rgba(0,0,0,0.62)',
    fontSize: '16px',
  }} 
  className={`height-${props.value}`}>{props.children}</div>;
const bahon_dt=(
<Row justify="space-around" align="middle" gutter={16}>
      <Col span={6}>
        <DemoBox  value={100}>{data?.name}</DemoBox>
      </Col>
      
      <Col span={6}>
        <DemoBox value={80}>{data?.mobile_number}</DemoBox>
      </Col>
      <Col span={6}>
        <DemoBox value={50}>Balance: {data?.account_balance?.toFixed(2)} TK</DemoBox>
      </Col>
      <Col span={6}>
        <DemoBox value={120}>{data?.email}</DemoBox>
      </Col>
    </Row>
)
  const Pageheader = (
    <>
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Bahon Details "
      subTitle="Finance Management"
    ></PageHeader>
    <br></br>
    </>
  );

  return (
    <Content>
      {Pageheader}

      {bahon_dt}

      {bahon_details}
    </Content>
  );
};

export default connect(null, {
  getBahonDetails,
})(BahonDetails);
