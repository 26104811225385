import React from "react";
import { Form, Input, Button, Layout, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Logo from "./B1.png";
import { login } from "../actions/auth";
import { connect } from "react-redux";
const { Footer } = Layout;

const Root = styled.div`
  position: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Card = styled.div`
  position: reletive;
  box-shadow: -2px 6px 17px 2px rgba(0, 0, 0, 0.37);
  transition: 0.3s;
  width: 40%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const Login = ({login}) => {
  const onFinish = (values) => {
    login(values)
  };
  const ROOT=()=>{
    return( <Root>
      <Card>
        <Col
          span={24}
          style={{
            alignSelf: "center",
            borderRadius: "10px",
            height: "500px",
            backgroundColor: "#fff",
            padding: "20px",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <img src={Logo} alt="bahonexpress" style={{ width: "100px", alignSelf: "center" }} />
          <h2 style={{ textAlign: "center" }}>Admin Panel</h2>
          <Form
            style={{
              alignSelf: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="userID"
              rules={[
                {
                  required: true,
                  message: "ID is required",
                },
              ]}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                
                placeholder="USER ID"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                
              >

                Log in
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Card>
    </Root>)
  }
  const FOOTER=()=>{
    return(<Footer style={{ textAlign: "center", height: "10vh" }}>
    <Footer style={{ textAlign: "center" }}>
      Copyright © 2021-2022 BahonExpress.com. All rights reserved.{" "}
    </Footer>
  </Footer>)
  }
  return (
    <Layout
      style={{ minHeight: "100vh", display: "flex", justifyContent: "center" }}
    >
      
     {ROOT()}
     {FOOTER()}
      
    </Layout>
  );
};

export default connect(null,{login})(Login);
